.App {
  /* text-align: center; */
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.danger-zone {
  border: 1px solid red;
  padding: 5px;
  margin-top: 10px;
}

.survey-delete {
  text-align: right;
}

.survey-delete.btn{
  background-color: red;
  padding: 5px
}

.float-right {
  float: right;
}

.app-settings {
  position: relative;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
